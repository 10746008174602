// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
// import 'slick-carousel';
// import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function minimizeHeader() {
  const header = document.querySelector('.header');

  if (window.pageYOffset > 0) {
    header.classList.add('is-minimize');
  } else {
    header.classList.remove('is-minimize');
  }
}

const insertButton = () => {
  const mainMenu = document.getElementById('sidebar-menu');
  if (!mainMenu) return;
  const buttonMarkup = '<span class="submenu-toggle"></span>';
  const listItems = mainMenu.querySelectorAll(
    '.menu-item.menu-item-has-children'
  );

  listItems.forEach((listItem) => {
    if (
      listItem.classList.contains('has-submenu-toggle') ||
      listItem.querySelector('button')
    )
      return;
    listItem.insertAdjacentHTML('afterbegin', buttonMarkup);
    listItem.classList.add('has-submenu-toggle');
  });
};

const openAccordion = (accordion, parent) => {
  const toggler = accordion.querySelector('.submenu-toggle');
  const content = accordion.querySelector('ul.sub-menu');
  const contentHeight = parseInt(content.scrollHeight + 16);
  toggler.classList.add('is-active');
  content.classList.add('is-active');
  content.style.maxHeight = contentHeight + 'px';
  parent.style.maxHeight =
    parseInt(parent.style.maxHeight) + contentHeight + 'px';
};

const closeAccordion = (accordion) => {
  const toggler = accordion.querySelector('.submenu-toggle');
  const content = accordion.querySelector('ul.sub-menu');
  toggler.classList.remove('is-active');
  content.classList.remove('is-active');
  content.style.maxHeight = 0;
};

const mobileMenuAccordion = () => {
  const mainMenu = document.getElementById('sidebar-menu');
  if (!mainMenu) return;
  const accordionItems = mainMenu.querySelectorAll(
    'li.menu-item-has-children.has-submenu-toggle'
  );
  accordionItems.forEach((accordion) => {
    const toggler = accordion.querySelector('.submenu-toggle');
    const content = accordion.querySelector('ul');
    const parent = accordion.parentElement;
    const closestItems = accordion
      .closest('ul')
      .querySelectorAll('li.menu-item-has-children.has-submenu-toggle');

    toggler.onclick = () => {
      if (content.classList.contains('is-active')) {
        closeAccordion(accordion);
      } else {
        closestItems.forEach((accordion) => closeAccordion(accordion));
        openAccordion(accordion, parent);
      }
    };
  });
};

const windowWidthVariadble = () => {
  const root = document.documentElement;
  const windowWidth = window.innerWidth;

  root.style.setProperty('--window-width', windowWidth + 'px');
};

const beforeArfterSlider = () => {
  $('.before-after-slider').on('input change', (e) => {
    const sliderPos = e.target.value;
    // Update the width of the foreground image
    $('.foreground-img').css('width', `${sliderPos}%`);
    // Update the position of the slider button
    $('.slider-button').css('left', `calc(${sliderPos}% - 3rem)`);
  });
};

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  // $('.matchHeight').matchHeight();
  minimizeHeader();
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  insertButton();
  mobileMenuAccordion();
  windowWidthVariadble();
  beforeArfterSlider();

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
  windowWidthVariadble();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  minimizeHeader();
});
